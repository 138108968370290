import "./styles.scss";
import Guess from "./Guess";
import Keyboard from "./Keyboard";
import {useState} from "react";
import {submissionAdded} from "./gamesSlice";
import {useDispatch} from "react-redux";

function Board(props) {
  const [newGuess, setNewGuess] = useState("");
  const dispatch = useDispatch();
  const guessList = props.submissions.map((item, index) => {
    return (<li key={index}><Guess word={item} answer={props.answer}/></li>);
  });
  if (props.submissions.length < props.maxTries) {
    guessList.push(<li key="newguess"><Guess word={newGuess} input={true} /></li>);
  }
  for (let i = props.submissions.length + 1; i < props.maxTries; i++) {
    guessList.push(<li key={i}><Guess /></li>);
  }

  function addCharacter(c) {
    setNewGuess(newGuess.concat(c));
  }

  function backspaceCharacter() {
    setNewGuess(newGuess.slice(0, -1));
  }

  function handleInput(key) {
    if (key === "!" || key === "Enter") {
      doSubmit();
    } else if (key === "<" || key === "Backspace") {
      backspaceCharacter();
    } else if (key.length === 1) {
      if (newGuess.length < 5) {
        addCharacter(key);
      }
    }
  }

  function doSubmit() {
    dispatch(submissionAdded({answer: props.answer, word: newGuess.toLowerCase()}));
    setNewGuess("");
  }

  function getHints() {
    const hints = {
      "correct": "",
      "incorrect": "",
      "partial": ""
    };

    const answerLetters = props.answer.split("");
    for (const sub of props.submissions) {
      sub.split("").forEach((item, index) => {
        const answerIndex = answerLetters.indexOf(item);
        let bucket;
        if (index === answerIndex) {
          bucket = "correct";
        } else if (answerIndex !== -1) {
          bucket = "partial";
        } else {
          bucket = "incorrect";
        }
        hints[bucket] += item;
      });
    }
    return hints;
  }

  return (
    <div onKeyUp={(event) => handleInput(event.key)} tabIndex={-1} className="board">
      <ul>
        {guessList}
      </ul>
      <Keyboard hints={getHints()} onInput={handleInput} />
    </div>
  );
}
export default Board;