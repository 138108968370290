import Board from "./Board";
import {useSelector, useDispatch} from "react-redux";
import {gameAdded} from "./gamesSlice";
import {useEffect} from "react";

function Game(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(gameAdded({
      id: props.answer,
      submissions: [],
      maxTries: 6,
      win: false
    }));
  }, [props.answer, dispatch]);

  const games = useSelector(state => state.games);
  const game = games.find(g => g.id === props.answer);
  if (game) {
    return (
      <Board answer={game.id} submissions={game.submissions} maxTries={game.maxTries}/>
    );
  }
  return (<div />)
}
export default Game;