import {createSlice} from "@reduxjs/toolkit";

const initialState = [];

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
    gameAdded: (state, action) => {
      const game = state.find(game => game.id === action.payload.id);
      if (!game) {
        state.push(action.payload);
      }
    },
    submissionAdded: (state, action) => {
      const {answer, word} = action.payload;
      const game = state.find(game => game.id === answer);
      if (
        game
        && word.length === 5
        && !game.submissions.includes(word)
        && game.submissions.length < game.maxTries
      ) {
        game.submissions.push(word);
      }
      if (word === answer) {
        game.win = true;
      }
    }
  }
});

export const { gameAdded, submissionAdded } = gamesSlice.actions;

export default gamesSlice.reducer;