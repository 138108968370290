import {ReactComponent as Backspace} from "./backspace.svg";

export default function Keyboard(props) {
  const layout = [
    "qwertyuiop",
    "asdfghjklñ",
    "!zxcvbnm<"
  ];

  function getHintClass(k, hints) {
    hints = { "enter": "!", "backspace": "<", ...hints };
    for (const className in hints) {
      if (hints[className].split("").includes(k)) {
        return className;
      }
    }
    return "blank";
  }

  function getKeyLabel(k) {
    if (k === "!") {
      return "Enter";
    } else if (k === "<") {
      return (<Backspace height={20} width={20} />);
    }
    return k;
  }

  const rows = layout.map((layoutRow, index) => {
    const row = layoutRow.split("").map((key, index) => {
      return (
        <li key={key}>
          <button onMouseDown={(event) => props.onInput(key, event)}
                  className={getHintClass(key, props.hints)}>{getKeyLabel(key)}</button>
        </li>
      );
    });
    return (
      <li key={index}>
        <ul>{row}</ul>
      </li>
    );
  });

  return (
    <div className="keyboard">
      <ul>
        {rows}
      </ul>
    </div>
  );

}