import React, {useState} from 'react';
import {Routes, Route, Outlet, Link, useLocation, useParams} from "react-router-dom";
import './App.scss';
import Game from "./components/Game";
import words from "./components/words5.json";
import words_es from "./components/words5_es.json";
import {useSelector} from "react-redux";
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import {ArrowLeftCircleFill, ArrowRightCircleFill, Puzzle } from "react-bootstrap-icons";
import {Card, Container} from "react-bootstrap";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/newgame" element={<NewGame />} />
        <Route path="/nuevojuego" element={<NewGame lang="es"/>} />
        <Route path="/bday/:page" element={<BDay />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const [ri, setRi] = useState(Math.floor(Math.random() * words.length));
  const [riEs, setRiEs] = useState(Math.floor(Math.random() * words_es.length));
  return (
    <div className="App">
      <header className="App-header">
        Pordle
      </header>
      <Nav as="ul" className="mainNav justify-content-center">
        <Nav.Item as="li">
          <Nav.Link as={Link} className="p-1" to="/"><Button size="sm" variant="outline-success">Home</Button></Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link as={Link} className="p-1"  to="/bday/welcome"><Button size="sm" variant="outline-success">B-DAY!</Button></Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link as={Link} className="p-1"  to={`/newgame?w=${ri}`} onClick={() => setRi(Math.floor(Math.random() * words.length))}><Button size="sm" variant="outline-success">New Game</Button></Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link as={Link} className="p-1"  to={`/nuevojuego?w=${riEs}`} onClick={() => setRiEs(Math.floor(Math.random() * words_es.length))}><Button size="sm" variant="outline-success">Nuevo Juego</Button></Nav.Link>
        </Nav.Item>
      </Nav>
      <Outlet />
      <footer>Pordle v0.3</footer>
    </div>
  );
}

function Home() {
  return (
    <Container className="d-flex justify-content-center" >
      <Card style={{ width: '30rem' }}>
        <Card.Header>Welcome to Pordle!</Card.Header>
        <Card.Body>
          <blockquote className="blockquote mb-0">
            <p>Pilar's Wordle, eh? eh? :D</p>
          </blockquote>
        </Card.Body>
      </Card>
    </Container>
  );
}

function NewGame(props) {
  const location = useLocation();
  const q = new URLSearchParams(location.search);
  const randomWord = props.lang === "es" ? words_es[q.get("w")] : words[q.get("w")];
  return (
    <div>
      <Game answer={randomWord.word} />
      <div style={{"backgroundColor": "white", "color": "white"}}>{randomWord.word}</div>
    </div>
  );
}

function BdayStatus(props) {
  const games = useSelector(state => state.games);
  let output = [];
  for (const riddleWord of props.riddleWords) {
    const game = games.find(g => g.id === riddleWord);
    if (game && game.win === true) {
      output.push(<button key={riddleWord} className="correct">{riddleWord}</button>);
    } else {
      output.push(<button key={riddleWord} className="blank">#####</button>);
    }
  }
  return (
    <div className="bday-status">
      <Puzzle className="bday-puzzle" fontSize="2.5rem" color="#d3d6da" />
      {output}
    </div>
  );
}

function BDay() {
  const [riddleWords] = useState([
    "ariel",
    "loves",
    "pilar",
  ]);
  const games = useSelector(state => state.games);
  const params = useParams();

  function getChallenge(word, prevLink, nextLink) {
    const game = games.find(g => g.id === word);
    return (
      <div>
        <ul className="bdayNav">
          <li><Link to={`/bday/${prevLink.url}`}>{prevLink.label}</Link></li>
          <li>{ game && game.win ? <Link to={`/bday/${nextLink.url}`}>{nextLink.label}</Link> : "" }</li>
        </ul>
        <BdayStatus riddleWords={riddleWords} />
        <Game answer={word} />
      </div>
    );
  }

  function getNavLink(direction, url, label) {
    if (direction === "prev") {
      return {url: url, label: (<Button variant="outline-secondary"><ArrowLeftCircleFill className="left-side" />{label}</Button>)}
    }
    return {url: url, label: (<Button variant="outline-secondary">{label}<ArrowRightCircleFill className="right-side"/></Button>)}
  }

  switch (params.page) {
    case "welcome":
      return (
      <Container className="d-flex justify-content-center" >
        <Card style={{ width: '30rem' }}>
          <Card.Header>Welcome Adventurer!</Card.Header>
          <Card.Body>
            <blockquote className="blockquote mb-0">
              <p>Can you unlock the SUPER SECRET message?</p>
              <p>Choooooooooose wisely!</p>
              <Link to="/bday/1"><Button variant="outline-secondary">Let's go!</Button></Link>
            </blockquote>
          </Card.Body>
        </Card>
      </Container>
      );
    case "1":
      return getChallenge(riddleWords[0], getNavLink("prev", "welcome", "Back"), getNavLink("next", "2", "Next Challenge!"));
    case "2":
      return getChallenge(riddleWords[1], getNavLink("prev", "1", "Back"), getNavLink("next", "3", "Next Challenge!"));
    case "3":
      return getChallenge(riddleWords[2], getNavLink("prev", "2", "Back"), getNavLink("next", "yay", "Yay!"));
    case "yay":
      return (
      <Container className="d-flex justify-content-center" >
        <Card style={{ width: '30rem' }}>
          <Card.Header as="h1">FELIZ CUMPLE LINDA!</Card.Header>
          <Card.Body>
            <blockquote className="blockquote mb-0">
                 <p>Te amo Pilar, ahora y siempre.</p>
                 <p>- Ariel, 15 de marzo de 2022</p>
              <Link to="/bday/3"><Button variant="outline-secondary"><ArrowLeftCircleFill className="left-side" />Back</Button></Link>
            </blockquote>
          </Card.Body>
        </Card>
      </Container>
      );
    default:
      return (
        <h1>---</h1>
      );
  }

}