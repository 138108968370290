import "./styles.scss";
import LetterTile from "./LetterTile";

export default function Guess(props) {
  const answerLetters = props.answer ? props.answer.split("") : [];
  const word = props.word ? props.word.padEnd(5, " ") : "     ";
  const letters = word.split("").map((letter, index) => {
    let hint = "incorrect";
    if (letter === answerLetters[index]) {
      hint = "correct";
    } else if (answerLetters.includes(letter)) {
      hint = "partial";
    } else if (props.word === undefined || props.input) {
      hint = "blank"
    }
    return <li key={index}><LetterTile hint={hint} letter={letter}/> </li>;
  })

  return (
    <div className="guess">
      <ul>
        {letters}
      </ul>
    </div>
  );

}